import { first, forkJoin } from 'rxjs';
import { Component, OnInit } from '@angular/core';
import { Ingredient, Meal } from '../../../../../backend/node_modules/@prisma/client'
import { HttpClient, HttpHeaders } from '@angular/common/http';
import * as firebase from 'firebase/compat/app';
import { environment } from 'src/environments/environment';
import { MealWithIngredients, manipulateNumbersDown } from '../../../../../backend/core/thecoach';
import { FormArray, FormBuilder, FormGroup, Validators } from '@angular/forms';
import { calculateKcalFromMacros, patchIngredient } from 'src/app/core/thecoach';
import { HttpUserService } from 'src/app/services/mealplaner/http-user.service';


@Component({
  selector: 'app-meals-lib',
  template: `
    <div class="px-4 sm:px-6 lg:px-8" *ngIf="!loadingToggler; else dataloaded">
  <div class="sm:flex sm:items-center">
    <div class="sm:flex-auto">
      <h1 class="text-base font-semibold leading-6 text-gray-900">Meals</h1>
      <p class="mt-2 text-sm text-gray-700">A list of all the Meals in your account.</p>
    </div>

        <button type="button" (click)='onCreateNewTemplateMeal()' class="ml-4 rounded-md bg-teal-600 px-2.5 py-1.5 text-sm font-semibold text-white shadow-sm hover:bg-teal-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-teal-600">New Meal Template</button>
  </div>


        <table class="min-w-full divide-y divide-gray-300">
          <thead>
            <tr>
              <th scope="col" class="py-3.5 pl-4 pr-3 text-left text-sm font-semibold text-gray-900 sm:pl-0">Name</th>
              <th scope="col" class="px-3 py-3.5 text-left text-sm font-semibold text-gray-900">Ingredients</th>
              <th scope="col" class="px-3 py-3.5 text-left text-sm font-semibold text-gray-900">Macros</th>
              <th scope="col" class="relative py-3.5 pl-3 pr-4 sm:pr-0">
                <span class="sr-only">Edit</span>
              </th>
                       <th scope="col" class="relative py-3.5 pl-3 pr-4 sm:pr-0">
                <span class="sr-only">Copy</span>
              </th>
                                 <th scope="col" class="relative py-3.5 pl-3 pr-4 sm:pr-0">
                <span class="sr-only">Delete</span>
              </th>
            </tr>
          </thead>
          <tbody class="divide-y divide-gray-200">
            <tr *ngFor="let meal of mealList">
              <td class="whitespace-nowrap py-4 pl-4 pr-3 text-sm font-medium text-gray-900 sm:pl-0">
              <div>{{meal.name}}</div>


            </td>
              <td class="whitespace-nowrap px-3 py-4 text-sm text-gray-500 ">
            <p>{{meal.addedIngredients.length}}</p>
        </td>
              <td class="whitespace-nowrap px-3 py-4 text-sm text-gray-500 italic">
            <p>{{displayMacroInfo(meal)}}</p>
        </td>
              <td class="relative whitespace-nowrap py-4 pl-3 pr-4 text-right text-sm font-medium sm:pr-0">
                <button (click)="onEditMeal(meal)"  class="text-teal-600 hover:text-teal-900">Edit</button>
              </td>
                <td class="relative whitespace-nowrap py-4 pl-3 pr-4 text-right text-sm font-medium sm:pr-0">
              </td>
                        <td class="relative whitespace-nowrap py-4 pl-3 pr-4 text-right text-sm font-medium sm:pr-0">
            <svg (click)="onDelete(meal)"  xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor" class="w-5 h-5 stroke-red-500 cursor-pointer">
  <path stroke-linecap="round" stroke-linejoin="round" d="m14.74 9-.346 9m-4.788 0L9.26 9m9.968-3.21c.342.052.682.107 1.022.166m-1.022-.165L18.16 19.673a2.25 2.25 0 0 1-2.244 2.077H8.084a2.25 2.25 0 0 1-2.244-2.077L4.772 5.79m14.456 0a48.108 48.108 0 0 0-3.478-.397m-12 .562c.34-.059.68-.114 1.022-.165m0 0a48.11 48.11 0 0 1 3.478-.397m7.5 0v-.916c0-1.18-.91-2.164-2.09-2.201a51.964 51.964 0 0 0-3.32 0c-1.18.037-2.09 1.022-2.09 2.201v.916m7.5 0a48.667 48.667 0 0 0-7.5 0" />
</svg>

                      </td>
            </tr>

            <!-- More people... -->
          </tbody>
        </table>
</div>


<nav class="flex items-center justify-between border-t border-gray-200 bg-white px-4 py-3 sm:px-6" aria-label="Pagination">
  <div class="hidden sm:block">
    <p class="text-sm text-gray-700">
      Showing
      <span class="font-medium">{{(currentPage-1) *10 + 1}}</span>
      to
      <span class="font-medium">{{(currentPage-1)*10 + mealList.length}}</span>
      of
      <span class="font-medium">{{totalMealCounter}}</span>
      results
    </p>
  </div>
  <div class="flex flex-1 justify-between sm:justify-end">
    <div (click)="this.toggleLoader(); movePage(-1)" class="cursor-pointer relative inline-flex items-center rounded-md bg-white px-3 py-2 text-sm font-semibold text-gray-900 ring-1 ring-inset ring-gray-300 hover:bg-gray-50 focus-visible:outline-offset-0">Previous</div>

    <div (click)="this.toggleLoader();movePage(+1)" class="cursor-pointer relative ml-3 inline-flex items-center rounded-md bg-white px-3 py-2 text-sm font-semibold text-gray-900 ring-1 ring-inset ring-gray-300 hover:bg-gray-50 focus-visible:outline-offset-0">Next</div>
  </div>
</nav>


<ng-template #dataloaded>
  <ngx-skeleton-loader count="5" animation="progress" [theme]="{height: '50px'}"></ngx-skeleton-loader>
</ng-template>

    <div *ngIf="editMealToggler">
    <app-edit-meal-modal [inputMealForm]="inputMealForm" (cancel)="onCancel()" (save)="toggleSavingModal();updateMealAtDB($event)"></app-edit-meal-modal>
  </div>
 <div *ngIf="savingToggler">
 <app-saving-modal [title]="'Template Meal'"></app-saving-modal>
 </div>

 <div *ngIf="newMealTemplateToggler">
    <app-edit-meal-modal [inputMealForm]="inputMealForm" (cancel)="onCancel()" (save)="toggleSavingModal();updateMealAtDB($event)"></app-edit-meal-modal>
 </div>
  `,
  styles: [
  ]
})
export class MealsLibComponent {
  mealList: MealWithIngredients[] = [];
  editMealToggler = false;
  savingToggler = false;
  newMealTemplateToggler = false
  inputMealForm = this.fb.group({
    id: [''],
    mealDescription:[''],
    name: ['', Validators.required],
    saveToDB: false,
    addedIngredients: this.fb.array([])
  })

  loadingToggler = false;

  currentPage = 1;
  totalMealCounter: number | undefined = undefined

  constructor(private http: HttpClient, private fb: FormBuilder, private httpUser: HttpUserService) {
    this.toggleLoader()
    this.loadMeals()
  }

  toggleLoader() {
    this.loadingToggler = !this.loadingToggler
  }

  onEditMeal(meal: MealWithIngredients) {
    this.resetForm()
    this.inputMealForm.get('name')?.patchValue(meal.name)
    this.inputMealForm.get('id')?.patchValue(meal.id)
    this.inputMealForm.get('saveToDB')?.patchValue(true)
    this.inputMealForm.get('mealDescription')?.patchValue(meal.mealDescription);
    meal.addedIngredients.forEach(addedIng => {

      (this.inputMealForm.get('addedIngredients') as FormArray).push(patchIngredient(addedIng, false))
    })
    this.toggleEditModal()

  }

  toggleNewTemplateMeal() {
    this.newMealTemplateToggler = !this.newMealTemplateToggler

  }


  toggleEditModal() {
    this.editMealToggler = !this.editMealToggler;
  }


  onCancel() {

    this.resetForm();

    this.newMealTemplateToggler = false;
    this.editMealToggler = false;
  }


  loadMeals() {
    this.mealList = [];
    this.httpUser.currentUser$.pipe(first()).subscribe(user => {
      if (user) {
        return user.getIdToken(true).then((idToken) => {
          const headers = new HttpHeaders({ Authorization: 'Bearer ' + idToken })

          this.http.get<{ mealCount: number, meals: MealWithIngredients[] }>(`${environment.apiUrl}/meal-template?skip=${(this.currentPage - 1) * 10}`, { headers }).subscribe(transactionObject => {
            if (transactionObject && transactionObject.meals) {
              transactionObject.meals.map(meal => {
                meal.addedIngredients.map(addedIng => {
                  addedIng.ingredient = manipulateNumbersDown(addedIng.ingredient)
                })
              })
            }
            this.totalMealCounter = transactionObject.mealCount
            this.mealList = transactionObject.meals;
            this.toggleLoader()
          })


        }).catch((error) => {
          // Handle error
          this.toggleLoader()
          console.error('Error getting ID token:', error);
          throw error;
        });
      } else {
        this.toggleLoader()
        // Handle the case when no user is signed in
        console.error('No user signed in.');
        return null;
      }
    })

  }

  movePage(increment: number) {
    this.currentPage += increment;


    if (this.currentPage <= 0) {
      this.currentPage = 1;
      this.toggleLoader()
    } else if (this.totalMealCounter && this.currentPage > Math.ceil(this.totalMealCounter / 10)) {
      this.currentPage = Math.ceil(this.totalMealCounter / 10)
      this.toggleLoader()
    } else {

      this.mealList = [];
      this.httpUser.currentUser$.pipe(first()).subscribe(user => {
        if (user) {
          return user.getIdToken(true).then((idToken) => {
            const headers = new HttpHeaders({ Authorization: 'Bearer ' + idToken })

            this.http.get<{ mealCount: number, meals: MealWithIngredients[] }>(`${environment.apiUrl}/meal-template?skip=${(this.currentPage - 1) * 10}`, { headers }).subscribe(transactionObject => {
              if (transactionObject && transactionObject.meals) {
                transactionObject.meals.map(meal => {
                  meal.addedIngredients.map(addedIng => {
                    addedIng.ingredient = manipulateNumbersDown(addedIng.ingredient)
                  })
                })
              }
              this.totalMealCounter = transactionObject.mealCount
              this.mealList = transactionObject.meals;
              this.toggleLoader()
            })


          }).catch((error) => {
            // Handle error
            this.toggleLoader()
            console.error('Error getting ID token:', error);
            throw error;
          });
        } else {
          this.toggleLoader()
          // Handle the case when no user is signed in
          console.error('No user signed in.');
          return null;
        }
      })
    }

  }



  toggleSavingModal() {
    this.savingToggler = !this.savingToggler
  }

  updateMealAtDB(mealForm: FormGroup) {
    this.httpUser.currentUser$.pipe(first()).subscribe(user => {


      const formData = mealForm.value as MealWithIngredients;
      formData.saveToDB = true;
      if (user) {
        return user.getIdToken(true).then((idToken) => {
          const headers = new HttpHeaders({ Authorization: 'Bearer ' + idToken })

          this.http.post(environment.apiUrl + '/meal-template', { meal: formData }, { headers }).subscribe(data => {
            this.toggleSavingModal()
            this.toggleLoader()
            this.loadMeals()
            this.resetForm();
            //console.log("Server respondse: ", data);
          })


        }).catch((error) => {
          // Handle error
          this.toggleLoader()
          this.loadMeals()
          this.resetForm()
          console.error('Error getting ID token:', error);
          throw error;
        });
      } else {
        this.toggleLoader()
        // Handle the case when no user is signed in
        this.toggleSavingModal()
        this.resetForm()
        console.error('No user signed in.');
        return null;
      }

    })
  }




  onDelete(meal: Meal) {
    this.toggleLoader()
    this.httpUser.currentUser$.pipe(first()).subscribe(user => {

      if (user) {
        return user.getIdToken(true).then((idToken) => {
          const headers = new HttpHeaders({ Authorization: 'Bearer ' + idToken })

          this.http.delete(environment.apiUrl + '/meal-template/' + meal.id, { headers }).subscribe(data => {
            this.loadMeals()
            //console.log("Server respondse: ", data);
          })


        }).catch((error) => {
          // Handle error
          this.toggleLoader()
          console.error('Error getting ID token:', error);
          throw error;
        });
      } else {
        this.toggleLoader()
        // Handle the case when no user is signed in
        console.error('No user signed in.');
        return null;
      }
    })
  }

  displayMacroInfo(meal: MealWithIngredients) {

    let totalProteins = 0;
    let totalCarbs = 0;
    let totalFats = 0;
    let totalKcal = 0;

    meal.addedIngredients.forEach(addedIng => {

      const grams = addedIng.grams;
      totalProteins += (addedIng.ingredient.protein * grams / 100) || 0;
      totalCarbs += (addedIng.ingredient.carb * grams / 100) || 0;
      totalFats += (addedIng.ingredient.fat * grams / 100) || 0;

      totalKcal += Number(calculateKcalFromMacros({ protein: totalProteins, carbs: totalCarbs, fat: totalFats })) * (grams / 100) || 0

    })

    return 'P: ' + totalProteins.toFixed(0) + 'g C: ' + totalCarbs.toFixed(0) + 'g F: ' + totalFats.toFixed(0) + 'g Kcal: ' + totalKcal.toFixed(0)

  }

  resetForm() {
    (this.inputMealForm.get('addedIngredients') as FormArray).clear();
    this.inputMealForm.reset()
    this.inputMealForm.get('saveToDB')?.patchValue(true)
  }

  onCreateNewTemplateMeal() {
    this.resetForm();
    this.toggleNewTemplateMeal()
  }

}

