import { animate, style, transition, trigger } from '@angular/animations';
import {
  AfterViewInit,
  ChangeDetectionStrategy,
  Component,
  ElementRef,
  Input,
  NgZone,
  OnDestroy,
  OnInit,
  QueryList,
  ViewChild,
  ViewChildren,
} from '@angular/core';
import { Observable } from 'rxjs';
import { AppUser, UserRole } from 'src/app/core/thecoach';
import { AuthService } from 'src/app/services/auth.service';

@Component({
  selector: 'app-coach-navigation',
  template: `
    <!--
  This example requires some changes to your config:


  // tailwind.config.js
  module.exports = {
    // ...
    plugins: [
      // ...
      require('@tailwindcss/forms'),
    ],
  }

-->
    <!--
  This example requires updating your template:


  <html class="h-full bg-gray-100">
  <body class="h-full">

-->
    <div *ngIf="isLoggedIn$ | async; else forcelogin">
      <!-- Off-canvas menu for mobile, show/hide based on off-canvas menu state. -->
      <div
        *ngIf="isOffCanvasMenuDialog"
        class="relative z-40 lg:hidden"
        role="dialog"
        aria-modal="true"
      >
        <!--
      Off-canvas menu backdrop, show/hide based on off-canvas menu state.

      Entering: "transition-opacity ease-linear duration-300"
        From: "opacity-0"
        To: "opacity-100"
      Leaving: "transition-opacity ease-linear duration-300"
        From: "opacity-100"
        To: "opacity-0"
    -->
        <div
          @opacityLinear
          *ngIf="isOffCanvasMenu"
          class="fixed inset-0 bg-gray-600 bg-opacity-75"
        ></div>

        <div
          @translateX
          *ngIf="isOffCanvasMenu"
          class="fixed inset-0 z-40 flex"
        >
          <div
            *ngIf="isOffCanvasMenu"
            (click)="toggleOffCanvasMenu()"
            class="fixed inset-0"
          ></div>

          <!--
        Off-canvas menu, show/hide based on off-canvas menu state.

        Entering: "transition ease-in-out duration-300 transform"
          From: "-translate-x-full"
          To: "translate-x-0"
        Leaving: "transition ease-in-out duration-300 transform"
          From: "translate-x-0"
          To: "-translate-x-full"
      -->
          <div
            @translateX
            *ngIf="isOffCanvasMenu"
            class="relative flex w-full max-w-xs flex-1 flex-col bg-gray-800 pt-5 pb-4"
          >
            <!--
          Close button, show/hide based on off-canvas menu state.

          Entering: "ease-in-out duration-300"
            From: "opacity-0"
            To: "opacity-100"
          Leaving: "ease-in-out duration-300"
            From: "opacity-100"
            To: "opacity-0"
        -->
            <div
              @opacityInOut
              *ngIf="isOffCanvasMenu"
              class="absolute top-0 right-0 -mr-12 pt-2"
            >
              <button
                type="button"
                (click)="toggleOffCanvasMenu()"
                class="ml-1 flex h-10 w-10 items-center justify-center rounded-full focus:outline-none focus:ring-2 focus:ring-inset focus:ring-white"
              >
                <span class="sr-only">Close sidebar</span>

                <svg
                  class="h-6 w-6 text-white"
                  fill="none"
                  viewBox="0 0 24 24"
                  stroke-width="1.5"
                  stroke="currentColor"
                  aria-hidden="true"
                >
                  <path
                    stroke-linecap="round"
                    stroke-linejoin="round"
                    d="M6 18L18 6M6 6l12 12"
                  />
                </svg>
              </button>
            </div>

            <div class="flex flex-shrink-0 items-center px-4">
              <img
                class="h-8 w-auto"
                src=".././assets/icons/TCCLogo-min-white.webp"
                alt="The Coach Cloud"
              />
            </div>
            <div class="mt-5 h-0 flex-1 overflow-y-auto">
              <nav class="space-y-1 px-2">
                <!-- Current: "bg-gray-900 text-white", Default: "text-gray-300 hover:bg-gray-700 hover:text-white" -->
                <a
                  (click)="toggleOffCanvasMenu()"
                  [routerLink]="'/coach-dashboard'"
                  class="bg-gray-900 text-white group flex items-center rounded-md px-2 py-2 text-base font-medium"
                >
                  <!-- Current: "text-gray-300", Default: "text-gray-400 group-hover:text-gray-300" -->
                  <svg
                    class="text-gray-300 mr-4 h-6 w-6 flex-shrink-0"
                    fill="none"
                    viewBox="0 0 24 24"
                    stroke-width="1.5"
                    stroke="currentColor"
                    aria-hidden="true"
                  >
                    <path
                      stroke-linecap="round"
                      stroke-linejoin="round"
                      d="M2.25 12l8.954-8.955c.44-.439 1.152-.439 1.591 0L21.75 12M4.5 9.75v10.125c0 .621.504 1.125 1.125 1.125H9.75v-4.875c0-.621.504-1.125 1.125-1.125h2.25c.621 0 1.125.504 1.125 1.125V21h4.125c.621 0 1.125-.504 1.125-1.125V9.75M8.25 21h8.25M2.25 12l8.954-8.955c.44-.439 1.152-.439 1.591 0L21.75 12M4.5 9.75v10.125c0 .621.504 1.125 1.125 1.125H9.75v-4.875c0-.621.504-1.125 1.125-1.125h2.25c.621 0 1.125.504 1.125 1.125V21h4.125c.621 0 1.125-.504 1.125-1.125V9.75M8.25 21h8.25"
                    />
                  </svg>
                  Dashboard
                </a>

                <ng-container *ngFor="let menu of menus; let j = index">
                  <div class="relative flex py-2 items-center">
                    <div class="flex-grow border-t border-gray-400"></div>
                    <span class="flex-shrink mx-4 text-gray-400">{{
                      menu.maintitle
                    }}</span>
                    <div class="flex-grow border-t border-gray-400"></div>
                  </div>

                  <ng-container
                    *ngFor="let menuitem of menu.items; let i = index"
                  >
                    <a
                      (click)="toggleOffCanvasMenu(j, i)"
                      *ngIf="menuitem.showMenu"
                      [routerLink]="menuitem.menuRoute!"
                      class="text-gray-300 hover:bg-gray-700 hover:text-white group flex items-center rounded-md px-2 py-2 text-base font-medium"
                      [ngClass]="{
                        'bg-gray-700':
                          j === activeMenuIndex && i === activeSubMenuIndex,
                      }"
                    >
                      <ng-container *ngIf="menuitem.svgPath; else noSVG">
                        <svg
                          class="text-gray-400 group-hover:text-gray-300 mr-3 h-6 w-6 flex-shrink-0"
                          fill="none"
                          viewBox="0 0 24 24"
                          stroke-width="1.5"
                          stroke="currentColor"
                          aria-hidden="true"
                        >
                          <path
                            stroke-linecap="round"
                            stroke-linejoin="round"
                            [attr.d]="menuitem.svgPath"
                          />
                        </svg>
                      </ng-container>
                      <ng-template #noSVG>
                        <img
                          src="{{ menuitem.imgPath }}"
                          class="h-6 w-6 mr-3 text-gray-400 group-hover:text-gray-300 flex-shrink-0 filter invert opacity-50"
                        />
                      </ng-template>
                      {{ menuitem.menuTitle }}
                    </a>
                  </ng-container>
                </ng-container>
              </nav>
            </div>
          </div>

          <div class="w-14 flex-shrink-0" aria-hidden="true">
            <!-- Dummy element to force sidebar to shrink to fit close icon -->
          </div>
        </div>
      </div>

      <!-- Static sidebar for desktop -->
      <div
        class="z-20 hidden lg:fixed lg:inset-y-0 lg:flex lg:w-64 lg:flex-col"
      >
        <!-- Sidebar component, swap this element with another sidebar if you like -->
        <div class="flex min-h-0 flex-1 flex-col bg-gray-800">
          <div class="flex h-16 flex-shrink-0 items-center bg-gray-900 px-4">
            <img
              class="h-8 w-auto"
              src=".././assets/icons/TCCLogo-min-white.webp"
              alt="The Coach Cloud"
            />
          </div>
          <div class="flex flex-1 flex-col overflow-y-auto">
            <nav class="flex-1 space-y-1 px-2 py-4">
              <!-- Current: "bg-gray-900 text-white", Default: "text-gray-300 hover:bg-gray-700 hover:text-white" -->
              <a
                (click)="toggleOffCanvasMenu()"
                [routerLink]="'/coach-dashboard'"
                class="bg-gray-900 text-white group flex items-center rounded-md px-2 py-2 text-sm font-medium"
              >
                <!-- Current: "text-gray-300", Default: "text-gray-400 group-hover:text-gray-300" -->
                <svg
                  class="text-gray-300 mr-3 h-6 w-6 flex-shrink-0"
                  fill="none"
                  viewBox="0 0 24 24"
                  stroke-width="1.5"
                  stroke="currentColor"
                  aria-hidden="true"
                >
                  <path
                    stroke-linecap="round"
                    stroke-linejoin="round"
                    d="M2.25 12l8.954-8.955c.44-.439 1.152-.439 1.591 0L21.75 12M4.5 9.75v10.125c0 .621.504 1.125 1.125 1.125H9.75v-4.875c0-.621.504-1.125 1.125-1.125h2.25c.621 0 1.125.504 1.125 1.125V21h4.125c.621 0 1.125-.504 1.125-1.125V9.75M8.25 21h8.25"
                  />
                </svg>
                Dashboard
              </a>

              <ng-container *ngFor="let menu of menus; let j = index">
                <div class="relative flex py-2 items-center">
                  <div class="flex-grow border-t border-gray-400"></div>
                  <span class="flex-shrink mx-4 text-gray-400">{{
                    menu.maintitle
                  }}</span>
                  <div class="flex-grow border-t border-gray-400"></div>
                </div>

                <ng-container
                  *ngFor="let menuitem of menu.items; let i = index"
                >
                  <a
                    (click)="toggleOffCanvasMenu(j, i)"
                    *ngIf="menuitem.showMenu"
                    [routerLink]="menuitem.menuRoute!"
                    class="text-gray-300 hover:bg-gray-700 hover:text-white group flex items-center rounded-md px-2 py-2 text-sm font-medium"
                    [ngClass]="{
                      'bg-gray-700':
                        j === activeMenuIndex && i === activeSubMenuIndex,
                    }"
                  >
                    <ng-container *ngIf="menuitem.svgPath; else noSVG">
                      <svg
                        class="text-gray-400 group-hover:text-gray-300 mr-3 h-6 w-6 flex-shrink-0"
                        fill="none"
                        viewBox="0 0 24 24"
                        stroke-width="1.5"
                        stroke="currentColor"
                        aria-hidden="true"
                      >
                        <path
                          stroke-linecap="round"
                          stroke-linejoin="round"
                          [attr.d]="menuitem.svgPath"
                        />
                      </svg>
                    </ng-container>
                    <ng-template #noSVG>
                      <img
                        src="{{ menuitem.imgPath }}"
                        class="h-6 w-6 mr-3 text-gray-400 group-hover:text-gray-300 flex-shrink-0 filter invert opacity-50"
                      />
                    </ng-template>
                    {{ menuitem.menuTitle }}
                  </a>
                </ng-container>
              </ng-container>
            </nav>
          </div>
        </div>
      </div>
      <div class="flex flex-col lg:pl-64">
        <div
          class="sticky top-0 z-10 flex h-16 flex-shrink-0 bg-white shadow w-100% "
        >
          <button
            type="button"
            (click)="toggleOffCanvasMenu()"
            class="border-r border-gray-200 px-4 text-gray-500 focus:outline-none focus:ring-2 focus:ring-inset focus:ring-teal-500 lg:hidden"
          >
            <span class="sr-only">Open sidebar</span>
            <svg
              class="h-6 w-6"
              fill="none"
              viewBox="0 0 24 24"
              stroke-width="1.5"
              stroke="currentColor"
              aria-hidden="true"
            >
              <path
                stroke-linecap="round"
                stroke-linejoin="round"
                d="M3.75 6.75h16.5M3.75 12h16.5m-16.5 5.25H12"
              />
            </svg>
          </button>
          <div class="flex flex-1 justify-between px-4">
            <div class="flex flex-1">
              <div
                *ngIf="authService.appUser$ | async as appUser"
                class="flex items-center justif-start"
              >
                <img
                  *ngIf="appUser.brandImgUrl"
                  class="h-4/6"
                  src="{{ appUser.brandImgUrl }}"
                  alt=""
                />
              </div>
            </div>

            <div class="ml-4 flex items-center lg:ml-6">
              <!-- Profile dropdown -->
              <div class="relative ml-3">
                <div>
                  <button
                    type="button"
                    (click)="toggleMenu()"
                    class="flex max-w-xs items-center rounded-full bg-white text-sm focus:outline-none focus:ring-2 focus:ring-teal-500 focus:ring-offset-2"
                    id="user-menu-button"
                    aria-expanded="false"
                    aria-haspopup="true"
                  >
                    <span class="sr-only">Open user menu</span>

                    <div *ngIf="authService.appUser$ | async as appUser">
                      <div
                        class="h-12 w-12 overflow-hidden rounded-full bg-gray-100"
                        *ngIf="!appUser.imgUrl"
                      >
                        <svg
                          class="h-full w-full text-gray-300"
                          fill="currentColor"
                          viewBox="0 0 24 24"
                        >
                          <path
                            d="M24 20.993V24H0v-2.996A14.977 14.977 0 0112.004 15c4.904 0 9.26 2.354 11.996 5.993zM16.002 8.999a4 4 0 11-8 0 4 4 0 018 0z"
                          />
                        </svg>
                      </div>

                      <img
                        *ngIf="appUser.imgUrl"
                        class="h-8 w-8 rounded-full"
                        src="{{ appUser.imgUrl }}"
                        alt=""
                      />
                    </div>
                  </button>
                </div>

                <!--
              Dropdown menu, show/hide based on menu state.

              Entering: "transition ease-out duration-100"
                From: "transform opacity-0 scale-95"
                To: "transform opacity-100 scale-100"
              Leaving: "transition ease-in duration-75"
                From: "transform opacity-100 scale-100"
                To: "transform opacity-0 scale-95"
            -->
                <div
                  @opacityScale
                  *ngIf="isMenu"
                  class="absolute right-0 z-10 mt-2 w-48 origin-top-right rounded-md bg-white py-1 shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none"
                  role="menu"
                  aria-orientation="vertical"
                  aria-labelledby="user-menu-button"
                  tabindex="-1"
                >
                  <!-- Active: "bg-gray-100", Not Active: "" -->
                  <a
                    (click)="toggleMenu()"
                    [routerLink]="'/settings'"
                    class="block px-4 py-2 text-sm text-gray-700"
                    role="menuitem"
                    tabindex="-1"
                    id="user-menu-item-0"
                    >Settings</a
                  >

                  <a
                    (click)="onSignOut(); toggleMenu()"
                    class="block px-4 py-2 text-sm text-gray-700"
                    role="menuitem"
                    tabindex="-1"
                    id="user-menu-item-2"
                    >Sign out</a
                  >
                </div>
              </div>
            </div>
          </div>
        </div>

        <main class="flex-1">
          <div class="py-2">
            <div class="mx-auto w-full px-4 sm:px-6 lg:px-8">
              <router-outlet></router-outlet>
            </div>
          </div>
        </main>
      </div>
    </div>
    <ng-template #forcelogin><router-outlet></router-outlet></ng-template>
  `,
  styles: [],
  animations: [
    trigger('opacityLinear', [
      transition(':enter', [
        style({ opacity: 0 }),
        animate('300ms linear', style({ opacity: 1 })),
      ]),
      transition(':leave', [
        style({ opacity: 1 }),
        animate('300ms linear', style({ opacity: 0 })),
      ]),
    ]),
    trigger('translateX', [
      transition(':enter', [
        style({ transform: 'translateX(-100%)' }),
        animate('300ms ease-in-out', style({ transform: 'translateX(0)' })),
      ]),
      transition(':leave', [
        style({ transform: 'translateX(0)' }),
        animate('300ms ease-in-out', style({ transform: 'translateX(-100%)' })),
      ]),
    ]),
    trigger('opacityInOut', [
      transition(':enter', [
        style({ opacity: 0 }),
        animate('300ms ease-in-out', style({ opacity: 1 })),
      ]),
      transition(':leave', [
        style({ opacity: 1 }),
        animate('300ms ease-in-out', style({ opacity: 0 })),
      ]),
    ]),
    trigger('opacityScale', [
      transition(':enter', [
        style({ opacity: 0, transform: 'scale(.95)' }),
        animate('100ms ease-out', style({ opacity: 1, transform: 'scale(1)' })),
      ]),
      transition(':leave', [
        style({ opacity: 1, transform: 'scale(1)' }),
        animate('75ms ease-in', style({ opacity: 0, transform: 'scale(.95)' })),
      ]),
    ]),
  ],
})
export class CoachNavigationComponent implements OnInit {
  @Input() user!: AppUser;
  isOffCanvasMenu = false;
  isOffCanvasMenuDialog = false;
  isMenu = false;
  isLoggedIn$: Observable<boolean> | undefined;

  activeMenuIndex: number | undefined;
  activeSubMenuIndex: number | undefined;
  observer: ResizeObserver | undefined;
  menus: {
    maintitle: string;
    items: {
      showMenu: boolean;
      menuTitle: string;
      menuRoute: string;
      svgPath?: string;
      imgPath?: string;
    }[];
  }[] = [
    {
      maintitle: 'Training',
      items: [],
    },

    {
      maintitle: 'Logging',
      items: [],
    },

    {
      maintitle: 'Custom Models',
      items: [],
    },
    {
      maintitle: 'Meal Planner',
      items: [],
    },
    {
      maintitle: 'Clients',
      items: [],
    },
    {
      maintitle: 'Settings',
      items: [],
    },
  ];

  constructor(protected authService: AuthService) {}

  ngOnInit(): void {
    this.isLoggedIn$ = this.authService.isLoggedInAppUser;
    this.initMenu();
  }

  getMenuCategories() {
    return Object.keys(this.menus);
  }

  toggleOffCanvasMenu(index?: number, subMenuIndex?: number) {
    this.isOffCanvasMenu = !this.isOffCanvasMenu;
    if (index || index === 0) this.activeMenuIndex = index;
    if (subMenuIndex || subMenuIndex === 0)
      this.activeSubMenuIndex = subMenuIndex;
    if (this.isOffCanvasMenuDialog) {
      setTimeout(() => {
        this.isOffCanvasMenuDialog = !this.isOffCanvasMenuDialog;
      }, 400);
    } else {
      this.isOffCanvasMenuDialog = !this.isOffCanvasMenuDialog;
    }
  }

  onSignOut() {
    this.authService.SignOut().then(() => {
      location.reload();
    });
  }

  toggleMenu() {
    this.isMenu = !this.isMenu;
  }

  initMenu() {
    this.menus.forEach((menu, index) => {
      switch (menu.maintitle) {
        case 'Training':
          this.menus[index].items = [
            {
              showMenu: true,
              menuTitle: 'Template Trainingplans',
              menuRoute: '/trainingplan-library',
              svgPath:
                'M2.25 12.75V12A2.25 2.25 0 014.5 9.75h15A2.25 2.25 0 0121.75 12v.75m-8.69-6.44l-2.12-2.12a1.5 1.5 0 00-1.061-.44H4.5A2.25 2.25 0 002.25 6v12a2.25 2.25 0 002.25 2.25h15A2.25 2.25 0 0021.75 18V9a2.25 2.25 0 00-2.25-2.25h-5.379a1.5 1.5 0 01-1.06-.44z',
            },
            {
              showMenu: true,
              menuTitle: 'Assigned Traininplans',
              menuRoute: '/coach-assigned-trainingplans',
              svgPath:
                'M15.75 17.25v3.375c0 .621-.504 1.125-1.125 1.125h-9.75a1.125 1.125 0 01-1.125-1.125V7.875c0-.621.504-1.125 1.125-1.125H6.75a9.06 9.06 0 011.5.124m7.5 10.376h3.375c.621 0 1.125-.504 1.125-1.125V11.25c0-4.46-3.243-8.161-7.5-8.876a9.06 9.06 0 00-1.5-.124H9.375c-.621 0-1.125.504-1.125 1.125v3.5m7.5 10.375H9.375a1.125 1.125 0 01-1.125-1.125v-9.25m12 6.625v-1.875a3.375 3.375 0 00-3.375-3.375h-1.5a1.125 1.125 0 01-1.125-1.125v-1.5a3.375 3.375 0 00-3.375-3.375H9.75',
            },
            {
              showMenu: true,
              menuTitle: 'Exercise Library',
              menuRoute: '/exercise-library',
              svgPath:
                'M9 12h3.75M9 15h3.75M9 18h3.75m3 .75H18a2.25 2.25 0 002.25-2.25V6.108c0-1.135-.845-2.098-1.976-2.192a48.424 48.424 0 00-1.123-.08m-5.801 0c-.065.21-.1.433-.1.664 0 .414.336.75.75.75h4.5a.75.75 0 00.75-.75 2.25 2.25 0 00-.1-.664m-5.8 0A2.251 2.251 0 0113.5 2.25H15c1.012 0 1.867.668 2.15 1.586m-5.8 0c-.376.023-.75.05-1.124.08C9.095 4.01 8.25 4.973 8.25 6.108V8.25m0 0H4.875c-.621 0-1.125.504-1.125 1.125v11.25c0 .621.504 1.125 1.125 1.125h9.75c.621 0 1.125-.504 1.125-1.125V9.375c0-.621-.504-1.125-1.125-1.125H8.25zM6.75 12h.008v.008H6.75V12zm0 3h.008v.008H6.75V15zm0 3h.008v.008H6.75V18z',
            },

            {
              showMenu: false,
              menuTitle: 'Model Dashboard',
              menuRoute: '/model-dashboard',

              svgPath:
                ' M2.25 7.125C2.25 6.504 2.754 6 3.375 6h6c.621 0 1.125.504 1.125 1.125v3.75c0 .621-.504 1.125-1.125 1.125h-6a1.125 1.125 0 0 1-1.125-1.125v-3.75ZM14.25 8.625c0-.621.504-1.125 1.125-1.125h5.25c.621 0 1.125.504 1.125 1.125v8.25c0 .621-.504 1.125-1.125 1.125h-5.25a1.125 1.125 0 0 1-1.125-1.125v-8.25ZM3.75 16.125c0-.621.504-1.125 1.125-1.125h5.25c.621 0 1.125.504 1.125 1.125v2.25c0 .621-.504 1.125-1.125 1.125h-5.25a1.125 1.125 0 0 1-1.125-1.125v-2.25Z',
            },
            {
              showMenu: false,
              menuTitle: 'Trainingplans New',
              menuRoute: '/tp-generator/new',
              svgPath:
                'M9 12h3.75M9 15h3.75M9 18h3.75m3 .75H18a2.25 2.25 0 002.25-2.25V6.108c0-1.135-.845-2.098-1.976-2.192a48.424 48.424 0 00-1.123-.08m-5.801 0c-.065.21-.1.433-.1.664 0 .414.336.75.75.75h4.5a.75.75 0 00.75-.75 2.25 2.25 0 00-.1-.664m-5.8 0A2.251 2.251 0 0113.5 2.25H15c1.012 0 1.867.668 2.15 1.586m-5.8 0c-.376.023-.75.05-1.124.08C9.095 4.01 8.25 4.973 8.25 6.108V8.25m0 0H4.875c-.621 0-1.125.504-1.125 1.125v11.25c0 .621.504 1.125 1.125 1.125h9.75c.621 0 1.125-.504 1.125-1.125V9.375c0-.621-.504-1.125-1.125-1.125H8.25zM6.75 12h.008v.008H6.75V12zm0 3h.008v.008H6.75V15zm0 3h.008v.008H6.75V18z',
            },
          ];
          break;
        case 'Logging':
          this.menus[index].items = [
            {
              showMenu: true,
              menuTitle: 'Weightlogs',
              menuRoute: '/coach-weightlogs',
              svgPath:
                'M2.25 12.75V12A2.25 2.25 0 014.5 9.75h15A2.25 2.25 0 0121.75 12v.75m-8.69-6.44l-2.12-2.12a1.5 1.5 0 00-1.061-.44H4.5A2.25 2.25 0 002.25 6v12a2.25 2.25 0 002.25 2.25h15A2.25 2.25 0 0021.75 18V9a2.25 2.25 0 00-2.25-2.25h-5.379a1.5 1.5 0 01-1.06-.44z',
            },
            {
              showMenu: true,
              menuTitle: 'Traininglogs',
              menuRoute: '/coach-traininglogs',
              svgPath:
                'M19.5 14.25v-2.625a3.375 3.375 0 00-3.375-3.375h-1.5A1.125 1.125 0 0113.5 7.125v-1.5a3.375 3.375 0 00-3.375-3.375H8.25m0 12.75h7.5m-7.5 3H12M10.5 2.25H5.625c-.621 0-1.125.504-1.125 1.125v17.25c0 .621.504 1.125 1.125 1.125h12.75c.621 0 1.125-.504 1.125-1.125V11.25a9 9 0 00-9-9z',
            },
          ];
          break;

        case 'Custom Models':
          this.menus[index].items = [
            {
              showMenu: true,
              menuTitle: 'Tracking Models',
              menuRoute: '/coach-custom-tracking',
              svgPath:
                'M10.5 6h9.75M10.5 6a1.5 1.5 0 11-3 0m3 0a1.5 1.5 0 10-3 0M3.75 6H7.5m3 12h9.75m-9.75 0a1.5 1.5 0 01-3 0m3 0a1.5 1.5 0 00-3 0m-3.75 0H7.5m9-6h3.75m-3.75 0a1.5 1.5 0 01-3 0m3 0a1.5 1.5 0 00-3 0m-9.75 0h9.75',
            },
            {
              showMenu: true,
              menuTitle: 'Assigned Tracking Models',
              menuRoute: '/coach-assigned-tracking',
              svgPath:
                'M 3 6 M 3 6 M 3 12 M 17.25 9.5 V 19.5 M 17.25 19.5 L 13.5 15.75 M 17.25 19.5 L 20.5 15.75 M 10.5 6 h 9.75 M 10.5 6 a 1.5 1.5 0 1 1 -3 0 m 3 0 a 1.5 1.5 0 1 0 -3 0 M 3 6 H 7.5 M 9.25 18 H 11 M 9.25 18 A 1.5 1.5 0 0 1 6.25 18 M 9.25 18 A 1.5 1.5 0 0 0 6.25 18 M 3 18 H 6.25 M 9 12 A 1.5 1.5 0 0 1 6 12 M 9 12 A 1.5 1.5 0 0 0 6 12 M 3 12 H 11',
            },

            {
              showMenu: true,
              menuTitle: 'Checkin Form',
              menuRoute: '/coach-custom-checkin-form',
              svgPath:
                'M6.429 9.75 2.25 12l4.179 2.25m0-4.5 5.571 3 5.571-3m-11.142 0L2.25 7.5 12 2.25l9.75 5.25-4.179 2.25m0 0L21.75 12l-4.179 2.25m0 0 4.179 2.25L12 21.75 2.25 16.5l4.179-2.25m11.142 0-5.571 3-5.571-3',
            },

            {
              showMenu: true,
              menuTitle: 'Assigned Checkin Form',
              menuRoute: '/coach-assigned-checkin-form',
              svgPath:
                'M7.5 7.5h-.75A2.25 2.25 0 0 0 4.5 9.75v7.5a2.25 2.25 0 0 0 2.25 2.25h7.5a2.25 2.25 0 0 0 2.25-2.25v-7.5a2.25 2.25 0 0 0-2.25-2.25h-.75m-6 3.75 3 3m0 0 3-3m-3 3V1.5m6 9h.75a2.25 2.25 0 0 1 2.25 2.25v7.5a2.25 2.25 0 0 1-2.25 2.25h-7.5a2.25 2.25 0 0 1-2.25-2.25v-.75',
            },
          ];
          break;

        case 'Meal Planner':
          this.menus[index].items = [
            {
              showMenu: true,
              menuTitle: 'Meal Plans',
              menuRoute: '/mealplaner',
              imgPath: '.././assets/icons/navbar/mealplanner/mealplans.webp',
            },
            {
              showMenu: true,
              menuTitle: 'Assign Meal Plans',
              menuRoute: '/mealplaner/assign/client',
              imgPath:
                '.././assets/icons/navbar/mealplanner/assigned_mealplans.webp',
            },
            {
              showMenu: true,
              menuTitle: 'Meal Templates',
              menuRoute: '/meals-lib',
              imgPath:
                '.././assets/icons/navbar/mealplanner/meal-templates.webp',
            },
            {
              showMenu: true,
              menuTitle: 'Ingredients Library',
              menuRoute: '/ingredient-lib',
              imgPath:
                '.././assets/icons/navbar/mealplanner/ingredients_lib.webp',
            },
          ];
          break;

        case 'Clients':
          this.menus[index].items = [
            {
              showMenu: true,
              menuTitle: 'Clients Overview',
              menuRoute: '/clients-overview',
              svgPath:
                'M15 19.128a9.38 9.38 0 002.625.372 9.337 9.337 0 004.121-.952 4.125 4.125 0 00-7.533-2.493M15 19.128v-.003c0-1.113-.285-2.16-.786-3.07M15 19.128v.106A12.318 12.318 0 018.624 21c-2.331 0-4.512-.645-6.374-1.766l-.001-.109a6.375 6.375 0 0111.964-3.07M12 6.375a3.375 3.375 0 11-6.75 0 3.375 3.375 0 016.75 0zm8.25 2.25a2.625 2.625 0 11-5.25 0 2.625 2.625 0 015.25 0z',
            },
            {
              showMenu: true,
              menuTitle: 'Clients Management',
              menuRoute: '/clients-management',
              svgPath:
                'M18 18.72a9.094 9.094 0 003.741-.479 3 3 0 00-4.682-2.72m.94 3.198l.001.031c0 .225-.012.447-.037.666A11.944 11.944 0 0112 21c-2.17 0-4.207-.576-5.963-1.584A6.062 6.062 0 016 18.719m12 0a5.971 5.971 0 00-.941-3.197m0 0A5.995 5.995 0 0012 12.75a5.995 5.995 0 00-5.058 2.772m0 0a3 3 0 00-4.681 2.72 8.986 8.986 0 003.74.477m.94-3.197a5.971 5.971 0 00-.94 3.197M15 6.75a3 3 0 11-6 0 3 3 0 016 0zm6 3a2.25 2.25 0 11-4.5 0 2.25 2.25 0 014.5 0zm-13.5 0a2.25 2.25 0 11-4.5 0 2.25 2.25 0 014.5 0z',
            },
            {
              showMenu: true,
              menuTitle: 'Check Ins',
              menuRoute: '/coach-checkins',
              svgPath:
                'M2.25 13.5h3.86a2.25 2.25 0 012.012 1.244l.256.512a2.25 2.25 0 002.013 1.244h3.218a2.25 2.25 0 002.013-1.244l.256-.512a2.25 2.25 0 012.013-1.244h3.859m-19.5.338V18a2.25 2.25 0 002.25 2.25h15A2.25 2.25 0 0021.75 18v-4.162c0-.224-.034-.447-.1-.661L19.24 5.338a2.25 2.25 0 00-2.15-1.588H6.911a2.25 2.25 0 00-2.15 1.588L2.35 13.177a2.25 2.25 0 00-.1.661z',
            },
            {
              showMenu: true,
              menuTitle: 'Notes',
              menuRoute: '/notes',
              svgPath:
                'M16.862 4.487l1.687-1.688a1.875 1.875 0 112.652 2.652L10.582 16.07a4.5 4.5 0 01-1.897 1.13L6 18l.8-2.685a4.5 4.5 0 011.13-1.897l8.932-8.931zm0 0L19.5 7.125M18 14v4.75A2.25 2.25 0 0115.75 21H5.25A2.25 2.25 0 013 18.75V8.25A2.25 2.25 0 015.25 6H10',
            },
          ];
          break;
        case 'Settings':
          this.menus[index].items = [
            {
              showMenu: true,
              menuTitle: 'Settings',
              menuRoute: '/coach-settings',
              svgPath:
                'M11.42 15.17L17.25 21A2.652 2.652 0 0021 17.25l-5.877-5.877M11.42 15.17l2.496-3.03c.317-.384.74-.626 1.208-.766M11.42 15.17l-4.655 5.653a2.548 2.548 0 11-3.586-3.586l6.837-5.63m5.108-.233c.55-.164 1.163-.188 1.743-.14a4.5 4.5 0 004.486-6.336l-3.276 3.277a3.004 3.004 0 01-2.25-2.25l3.276-3.276a4.5 4.5 0 00-6.336 4.486c.091 1.076-.071 2.264-.904 2.95l-.102.085m-1.745 1.437L5.909 7.5H4.5L2.25 3.75l1.5-1.5L7.5 4.5v1.409l4.26 4.26m-1.745 1.437l1.745-1.437m6.615 8.206L15.75 15.75M4.867 19.125h.008v.008h-.008v-.008z',
            },
          ];
          break;

        default:
          console.log('no menu found');
      }
    });

    if (this.user.role === UserRole.Admin) {
      this.menus.push({
        maintitle: 'Admin Panel',
        items: [
          {
            showMenu: true,
            menuTitle: 'Dashboard',
            menuRoute: 'admin/dashboard',
            svgPath:
              'M2.25 12l8.954-8.955c.44-.439 1.152-.439 1.591 0L21.75 12M4.5 9.75v10.125c0 .621.504 1.125 1.125 1.125H9.75v-4.875c0-.621.504-1.125 1.125-1.125h2.25c.621 0 1.125.504 1.125 1.125V21h4.125c.621 0 1.125-.504 1.125-1.125V9.75M8.25 21h8.25M2.25 12l8.954-8.955c.44-.439 1.152-.439 1.591 0L21.75 12M4.5 9.75v10.125c0 .621.504 1.125 1.125 1.125H9.75v-4.875c0-.621.504-1.125 1.125-1.125h2.25c.621 0 1.125.504 1.125 1.125V21h4.125c.621 0 1.125-.504 1.125-1.125V9.75M8.25 21h8.25',
          },

          {
            showMenu: true,
            menuTitle: 'User Overview',
            menuRoute: 'admin/useroverview',
            svgPath:
              'M15 19.128a9.38 9.38 0 002.625.372 9.337 9.337 0 004.121-.952 4.125 4.125 0 00-7.533-2.493M15 19.128v-.003c0-1.113-.285-2.16-.786-3.07M15 19.128v.106A12.318 12.318 0 018.624 21c-2.331 0-4.512-.645-6.374-1.766l-.001-.109a6.375 6.375 0 0111.964-3.07M12 6.375a3.375 3.375 0 11-6.75 0 3.375 3.375 0 016.75 0zm8.25 2.25a2.625 2.625 0 11-5.25 0 2.625 2.625 0 015.25 0z',
          },
        ],
      });
    }
  }
}
