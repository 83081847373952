import {
  AddedIngredientWithIngredient,
  DayWithMeals,
  MealWithIngredients,
} from './../../../../../backend/core/thecoach';
import { ActivatedRoute, ParamMap, Router } from '@angular/router';
import {
  AbstractControl,
  FormArray,
  FormBuilder,
  FormGroup,
  Validators,
} from '@angular/forms';
import { Component, OnDestroy, OnInit } from '@angular/core';
import * as firebase from 'firebase/compat/app';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { environment } from 'src/environments/environment';
import { MealplanService } from 'src/app/services/mealplaner/mealplan.service';
import { MealPlanWithDays } from '../../../../../backend/core/thecoach';
import { Subscription, first, forkJoin } from 'rxjs';
import { Location } from '@angular/common';
import { HttpUserService } from 'src/app/services/mealplaner/http-user.service';
import { DaySeriesModel } from '@fullcalendar/core/internal';
import { atLeastOneValidation } from 'src/app/core/thecoach';

@Component({
  selector: 'app-mealplan-creator',
  template: `
    <div class="mx-auto max-w-7xl sm:px-6 lg:px-8">
      <!-- Content goes here -->
      <form [formGroup]="mealPlanForm">
        <div class="flex flex-col w-full">
          <div class="items-center w-full flex flex-row justify-between">
            <div class="flex flex-row w-full items-center">
              <input
                type="text"
                name="name"
                id="name"
                formControlName="name"
                class="block w-5/12 rounded-md border-0
          py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300
          placeholder:text-gray-400 focus:ring-2 focus:ring-inset
          focus:ring-teal-600 sm:text-sm sm:leading-6"
                placeholder="Enter Mealplan Name"
              />
              <button
                type="button"
                (click)="addNewDay()"
                class="rounded-md ml-4 bg-teal-600 px-2.5 py-1.5 text-sm font-semibold text-white shadow-sm hover:bg-teal-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-teal-600"
              >
                Add Day
              </button>
              <button
                type="button"
                (click)="saveMealplan()"
                [disabled]="!mealPlanForm.valid"
                class="disabled:opacity-25 rounded-md ml-4 bg-teal-600 px-2.5 py-1.5 text-sm font-semibold text-white shadow-sm hover:bg-teal-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-teal-600"
              >
                Save Mealplan
              </button>
            </div>
            <button
              type="button"
              [routerLink]="'/mealplaner/'"
              class="rounded-md ml-4 bg-yellow-600 px-2.5 py-1.5 text-sm font-semibold text-white shadow-sm hover:bg-yellow-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-yellow-600"
            >
              Cancel
            </button>
          </div>

          <div
            *ngFor="let day of days.controls; let i = index"
            class="flex flex-row w-full pl-2 pt-2 items-baseline"
          >
            <app-mealday-creator
              class="w-full"
              (delete)="onDeleteDay($event)"
              [index]="i"
              [dayForm]="convertToFormGroup(day)"
            ></app-mealday-creator>
            <!--
    <button type="button" [routerLink]="'/mealplaner/daycreator/new'" class="rounded-md ml-4 bg-teal-600 px-2.5 py-1.5 text-sm font-semibold text-white shadow-sm hover:bg-teal-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-teal-600">Create new Day</button>
-->
          </div>
        </div>
      </form>
    </div>
    <div *ngIf="savingToggler">
      <app-saving-modal [title]="'Mealplan'"></app-saving-modal>
    </div>
  `,
  styles: [],
})
export class MealplanCreatorComponent implements OnInit, OnDestroy {
  savingToggler: boolean = false;
  mealPlan: MealPlanWithDays | undefined = undefined;
  mealPlanSubscription: Subscription | undefined = undefined;
  mealPlanForm = this.fb.group(
    {
      name: ['', Validators.required],
      id: [''],
      days: this.fb.array([]),
    },
    { validator: this.atLeastOneDayValidation },
  );

  searchDayValue: string = '';

  constructor(
    private fb: FormBuilder,
    private http: HttpClient,
    private route: ActivatedRoute,
    private location: Location,
    private mealplanService: MealplanService,
    private httpUser: HttpUserService,
  ) {}

  ngOnDestroy(): void {
    this.mealPlanSubscription?.unsubscribe();
  }

  log() {
    //  console.log(this.mealPlanForm);
  }

  ngOnInit(): void {
    const queryParams = this.route.snapshot.queryParams;
    const params = this.route.snapshot.params;

    const id = params['id'];
    const copy = queryParams['copy'];

    // console.log('ID:', id);
    // console.log('Copy:', copy);

    if (id) {
      this.mealPlanSubscription = this.mealplanService.mealPlans$
        .pipe(first())
        .subscribe((mealPlans) => {
          this.mealPlan = mealPlans.find((mp) => mp.id === id);

          if (this.mealPlan) {
            if (copy) {
              this.mealPlan = this.prepareCopyMealplan(this.mealPlan);
            }

            //console.log('loadedMP', this.mealPlan)
            this.mealPlanForm.patchValue(this.mealPlan);
            this.mealPlan.days.forEach((day, indexDay) => {
              this.days.push(this.patchDay(day));
              day.meals.forEach((meal, indexMeal) => {
                (this.days.at(indexDay).get('meals') as FormArray).push(
                  this.patchMeal(meal),
                );
                meal.addedIngredients.forEach((addedIng) => {
                  (
                    (this.days.at(indexDay).get('meals') as FormArray)
                      .at(indexMeal)
                      .get('addedIngredients') as FormArray
                  ).push(this.patchAddedIngredient(addedIng, false));
                });
              });
            });
          }
        });
    }
  }

  atLeastOneDayValidation(control: AbstractControl) {
    return atLeastOneValidation(control, 'days');
  }

  prepareCopyMealplan(mealplan: MealPlanWithDays) {
    mealplan.id = '';
    mealplan.name = '';

    mealplan.days.forEach((day) => {
      day.id = '';
      day.mealPlanId = '';
      day.meals.forEach((meal) => {
        meal.id = '';
        meal.DayId = '';

        meal.addedIngredients.forEach((addedIng) => {
          addedIng.mealId = '';
          addedIng.id = '';
          addedIng.meal = undefined;
        });
      });
    });

    return mealplan;
  }

  patchDay(day: DayWithMeals) {
    return this.fb.group({
      name: day.name,
      id: [day.id],
      meals: this.fb.array([]),
    });
  }

  get days() {
    return this.mealPlanForm.get('days') as FormArray;
  }

  patchMeal(meal: MealWithIngredients) {
    return this.fb.group({
      name: [meal.name, Validators.required],
      id: [meal.id],
      saveToDB: false,
      mealDescription: [meal.mealDescription],
      addedIngredients: this.fb.array([]),
    });
  }

  patchAddedIngredient(
    addedIngredient: AddedIngredientWithIngredient,
    enableEdit: boolean,
  ) {
    return this.fb.group({
      id: [addedIngredient.id],
      grams: [
        { value: addedIngredient.grams || 0, disabled: enableEdit },
        Validators.required,
      ],
      ingredientId: [addedIngredient.ingredientId],
      ingredient: this.fb.group({
        id: [addedIngredient.ingredient.id],
        name: [addedIngredient.ingredient.name, [Validators.required]],
        merchant: [addedIngredient.ingredient.merchant || ''],
        packageSize: [addedIngredient.ingredient.packageSize || 0],
        packageUnitSize: [addedIngredient.ingredient.packageUnitSize || 'g'],
        packageCost: [addedIngredient.ingredient.packageCost || 0],
        protein: [addedIngredient.ingredient.protein || 0, Validators.required],
        fat: [addedIngredient.ingredient.fat || 0, Validators.required],
        saturatedFat: [addedIngredient.ingredient.saturatedFat || 0],
        carb: [addedIngredient.ingredient.carb || 0, Validators.required],
        sugar: [addedIngredient.ingredient.sugar || 0],
        fibre: [addedIngredient.ingredient.fibre || 0],
      }),
    });
  }

  addNewDay() {
    const dayForm = this.fb.group({
      name: [, Validators.required],
      meals: this.fb.array([]),
    });

    this.days.push(dayForm);
  }

  convertToFormGroup(value: AbstractControl): FormGroup {
    return value as FormGroup;
  }

  onDeleteDay(val: number) {
    this.days.removeAt(val);
  }

  toggleSaving() {
    // console.log('toggler status:', this.savingToggler)
    this.savingToggler = !this.savingToggler;
  }

  saveMealplan() {
    //    console.log(this.mealPlanForm)
    if (this.mealPlanForm) {
      const formData = this.mealPlanForm.value as MealPlanWithDays;
      //     console.log('Mealplan Data sent to server: ', formData);
      //    console.log(formData)
      this.httpUser.currentUser$.pipe(first()).subscribe((user) => {
        if (user) {
          user.getIdToken(true).then((idToken) => {
            const headers = new HttpHeaders({
              Authorization: 'Bearer ' + idToken,
            });
            this.http
              .post(
                environment.apiUrl + '/mealplans',
                {
                  mealplan: formData,
                },
                { headers: headers },
              )
              .subscribe((res) => {
                //  console.log('Server responded', res);
                this.toggleSaving();
                this.location.back();
              }),
              (error: Error) => {
                console.error('Error', error);
              };
          });
        }
      });
    }

    this.toggleSaving();
  }
}
