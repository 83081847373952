import { forEach } from 'lodash';
import {
  ChangeDetectionStrategy,
  Component,
  EventEmitter,
  Input,
  OnChanges,
  OnDestroy,
  OnInit,
  Output,
  SimpleChanges,
} from '@angular/core';
import { FormArray, FormBuilder, Validators } from '@angular/forms';
import {
  Exercise,
  InputSet,
  TemplateTrainingDay,
  TemplateTrainingSlot,
  TrainingSlotEntry,
} from 'src/app/core/thecoach';
import { TrainingplanService } from 'src/app/services/trainingplan.service';
import { Timestamp } from 'firebase/firestore';

@Component({
  selector: 'app-slot-input',
  changeDetection: ChangeDetectionStrategy.OnPush,
  template: `
    <div
      *ngIf="this.exerciseDescription"
      class="flex w-full justify-center flex-col"
    >
      <div class="flex w-full flex-row justify-between">
        <div class="flex pr-1">
          <p class="text-sm italic text-gray-700">
            <span class="font-semibold text-black">Description: </span
            >{{ exerciseDescription }}
          </p>
        </div>
      </div>
    </div>
    <div class="flex items-end justify-end">
      <button
        class="rounded-md bg-teal-600 px-0.5 py-0.5 text-sm font-semibold text-white shadow-sm hover:bg-teal-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-teal-600"
        (click)="toggleMachineSetting()"
      >
        <svg
          xmlns="http://www.w3.org/2000/svg"
          fill="none"
          viewBox="0 0 24 24"
          stroke-width="1.5"
          stroke="currentColor"
          class="w-6 h-6"
        >
          <path
            stroke-linecap="round"
            stroke-linejoin="round"
            d="M9.594 3.94c.09-.542.56-.94 1.11-.94h2.593c.55 0 1.02.398 1.11.94l.213 1.281c.063.374.313.686.645.87.074.04.147.083.22.127.324.196.72.257 1.075.124l1.217-.456a1.125 1.125 0 011.37.49l1.296 2.247a1.125 1.125 0 01-.26 1.431l-1.003.827c-.293.24-.438.613-.431.992a6.759 6.759 0 010 .255c-.007.378.138.75.43.99l1.005.828c.424.35.534.954.26 1.43l-1.298 2.247a1.125 1.125 0 01-1.369.491l-1.217-.456c-.355-.133-.75-.072-1.076.124a6.57 6.57 0 01-.22.128c-.331.183-.581.495-.644.869l-.213 1.28c-.09.543-.56.941-1.11.941h-2.594c-.55 0-1.02-.398-1.11-.94l-.213-1.281c-.062-.374-.312-.686-.644-.87a6.52 6.52 0 01-.22-.127c-.325-.196-.72-.257-1.076-.124l-1.217.456a1.125 1.125 0 01-1.369-.49l-1.297-2.247a1.125 1.125 0 01.26-1.431l1.004-.827c.292-.24.437-.613.43-.992a6.932 6.932 0 010-.255c.007-.378-.138-.75-.43-.99l-1.004-.828a1.125 1.125 0 01-.26-1.43l1.297-2.247a1.125 1.125 0 011.37-.491l1.216.456c.356.133.751.072 1.076-.124.072-.044.146-.087.22-.128.332-.183.582-.495.644-.869l.214-1.281z"
          />
          <path
            stroke-linecap="round"
            stroke-linejoin="round"
            d="M15 12a3 3 0 11-6 0 3 3 0 016 0z"
          />
        </svg>
      </button>
    </div>

    <div
      *ngIf="this.slot?.exerciseSetup"
      class="flex w-full justify-center flex-row"
    >
      <div class="flex pr-1">
        <p class="text-sm italic text-gray-700">
          <span class="font-semibold text-black">Setup: </span
          >{{ this.slot?.exerciseSetup }}
        </p>
      </div>
    </div>

    <div class="px-4 sm:px-6 lg:px-8">
      <form [formGroup]="slotInputForm" (ngSubmit)="onSubmit()">
        <div class="mt-2 flow-root">
          <div class="-mx-4 -my-2 overflow-x-auto sm:-mx-6 lg:-mx-8">
            <div
              class="flex justify-center min-w-full py-2 align-middle sm:px-6 lg:px-8"
            >
              <table class="min-w-fit divide-y divide-gray-300">
                <thead>
                  <tr class="divide-x divide-gray-200">
                    <th
                      scope="col"
                      class=" text-center px-2 text-sm font-semibold text-gray-900 sm:pl-0"
                    >
                      Set
                    </th>
                    <th
                      scope="col"
                      class="text-center px-2 text-sm font-semibold text-gray-900"
                    >
                      Weight[kg]
                    </th>
                    <th
                      scope="col"
                      class=" text-center px-2 text-sm font-semibold text-gray-900"
                    >
                      Reps
                    </th>
                    <th
                      scope="col"
                      class="text-center  px-2 text-sm font-semibold text-gray-900 sm:pr-0"
                    >
                      RIR
                    </th>
                  </tr>
                </thead>
                <tbody
                  class="divide-y divide-gray-200 bg-white"
                  formArrayName="inputSets"
                >
                  <tr
                    class="divide-x divide-gray-200 text-center"
                    *ngFor="let set of inputSets.controls; let i = index"
                    [formGroupName]="i"
                  >
                    <td class="whitespace-nowrap text-sm text-gray-900">
                      {{ i + 1 }}
                    </td>
                    <td class="whitespace-nowrap text-sm text-gray-500">
                      <input
                        *ngIf="
                          this.lastSessionSlot &&
                            this.lastSessionSlot.inputSets &&
                            this.lastSessionSlot.inputSets[i] &&
                            this.lastSessionSlot.inputSets[i].weight;
                          else blankweight
                        "
                        type="text"
                        (input)="onKeyDownInput()"
                        formControlName="weight"
                        inputmode="decimal"
                        step="any"
                        id="weightinput-{{ set }}"
                        class="block max-w-[5rem] w-full text-center border-0 text-gray-900 shadow-sm  placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-teal-600"
                        placeholder="{{
                          this.lastSessionSlot.inputSets[i].weight
                        }}"
                      />
                      <ng-template #blankweight>
                        <input
                          (input)="onKeyDownInput()"
                          type="text"
                          formControlName="weight"
                          step="any"
                          inputmode="decimal"
                          id="weightinput-{{ set }}"
                          class="block max-w-[5rem] w-full  text-center border-0 text-gray-900 shadow-sm  placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-teal-600"
                          placeholder="-"
                        />
                      </ng-template>
                    </td>

                    <td class="whitespace-nowrap text-sm text-gray-500">
                      <div
                        *ngIf="
                          this.lastSessionSlot &&
                            this.lastSessionSlot.inputSets &&
                            this.lastSessionSlot.inputSets[i] &&
                            this.lastSessionSlot.inputSets[i].reps;
                          else blankreps
                        "
                      >
                        <input
                          *ngIf="checkForAMREPS(this.slot?.reps); else noamrap"
                          (input)="onKeyDownInput()"
                          type="text"
                          formControlName="reps"
                          inputmode="decimal"
                          id="repsinput-{{ set }}"
                          class="block max-w-[8rem] text-xs border-0 text-center text-gray-900 shadow-sm  placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-teal-600"
                          placeholder="{{ this.slot?.reps }} | {{
                            this.lastSessionSlot.inputSets[i].reps
                          }}"
                        />

                        <ng-template #noamrap>
                          <input
                            type="text"
                            (input)="onKeyDownInput()"
                            formControlName="reps"
                            inputmode="decimal"
                            id="repsinput-{{ set }}"
                            class="block max-w-[8rem] border-0 text-center text-gray-900 shadow-sm  placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-teal-600"
                            placeholder="{{ this.slot?.reps }} | {{
                              this.lastSessionSlot.inputSets[i].reps
                            }}"
                          />
                        </ng-template>
                      </div>

                      <ng-template #blankreps>
                        <input
                          type="text"
                          (input)="onKeyDownInput()"
                          formControlName="reps"
                          inputmode="decimal"
                          id="repsinput-{{ set }}"
                          class="block max-w-[6rem] border-0 text-center text-gray-900 shadow-sm  placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-teal-600"
                          placeholder="{{ this.slot?.reps }}"
                        />
                      </ng-template>
                    </td>
                    <td class="whitespace-nowraptext-sm text-gray-500 italic">
                      {{ this.slot?.rir }}
                    </td>
                  </tr>

                  <!-- More people... -->
                </tbody>
              </table>
            </div>
          </div>
        </div>
        <div class="flex w-full justify-center">
          <div class="mt-2 w-5/6">
            <textarea
              rows="2"
              formControlName="comment"
              id="comment"
              (input)="onKeyDownInput()"
              placeholder="{{
                this.lastSessionSlot?.comment || 'Your comment here...'
              }}"
              class="block w-full rounded-md border-0 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-teal-600 sm:py-1.5 sm:text-sm sm:leading-6"
            ></textarea>
          </div>
        </div>
        <div class="flex w-full justify-around pt-4">
          <button
            (click)="cancelWorkout()"
            *ngIf="slot!.slot! === 1"
            type="button"
            class="rounded-md bg-red-600 px-3 py-2 text-sm font-semibold text-white shadow-sm hover:bg-teal-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-teal-600"
          >
            Cancel
          </button>
          <button
            *ngIf="slot!.slot! !== 1"
            (click)="goBack()"
            type="button"
            class="rounded-md bg-gray-400 px-3 py-2 text-sm font-semibold text-white shadow-sm hover:bg-teal-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-teal-600"
          >
            Back
          </button>
          <button
            *ngIf="slot!.slot !== slotAmount; else saveToWorkoutlog"
            type="submit"
            class="rounded-md bg-teal-600 px-3 py-2 text-sm font-semibold text-white shadow-sm hover:bg-teal-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-teal-600"
          >
            Save
          </button>

          <ng-template #saveToWorkoutlog>
            <button
              (click)="onsaveWorkoutlog()"
              type="button"
              class="rounded-md bg-teal-600 px-3 py-2 text-sm font-semibold text-white shadow-sm hover:bg-teal-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-teal-600"
            >
              Save Workoutlog
            </button>
          </ng-template>
        </div>
      </form>
    </div>

    <div>
      <app-exercise-setup-modal
        *ngIf="machineSettingToggler && this.slot"
        [slot]="this.slot!"
        [lastSessionSlot]="this.lastSessionSlot!"
        (close)="addMachineSetup($event)"
      >
        ></app-exercise-setup-modal
      >
    </div>
  `,
  styles: [],
})
export class SlotInputComponent implements OnInit, OnChanges {
  @Input() slot?: TemplateTrainingSlot;
  @Input() exerciseDescription?: string;
  @Input() slotAmount?: number;
  @Input() lastSessionSlot?: TemplateTrainingSlot;
  @Output() save = new EventEmitter<boolean>();
  @Output() back = new EventEmitter<boolean>();
  @Output() saveWorkoutlog = new EventEmitter<boolean>();
  @Output() cancelCurrentWorkout = new EventEmitter<boolean>();
  @Output() emitKeyDown = new EventEmitter<boolean>();

  machineSettingToggler = false;

  sets: Array<number> = [];

  slotInputForm = this.fb.group({
    comment: [''],
    inputSets: this.fb.array([]),
  });

  constructor(
    private fb: FormBuilder,
    private tpService: TrainingplanService,
  ) {}

  onKeyDownInput() {
    this.saveSlot();
    this.emitKeyDown.emit(true);
  }

  addMachineSetup(event: boolean) {
    if (event) {
      this.saveSlot();
    }
    this.toggleMachineSetting();
  }

  toggleMachineSetting() {
    this.machineSettingToggler = !this.machineSettingToggler;
  }

  toNumber(value: string): number {
    return Number(value);
  }

  ngOnInit(): void {
    if (!this.slot) throw new Error('NO INPUT SLOT');
    if (!this.slot.sets || isNaN(+this.slot.sets)) throw new Error('NO SETS!');

    if (this.lastSessionSlot?.exerciseSetup) {
      this.slot.exerciseSetup = this.lastSessionSlot.exerciseSetup;
    }
    this.sets = [];

    this.sets = Array(Number(this.slot.sets))
      .fill(1)
      .map((x, i) => i + 1);

    this.inputSets.clear();
    this.slotInputForm.reset();
    this.sets.forEach((set, index) => {
      this.addInputSet(index);
    });

    if (this.slot.inputSets) {
      this.inputSets.patchValue(this.slot.inputSets);
    }

    if (this.slot.comment) {
      this.slotInputForm.get('comment')?.patchValue(this.slot.comment);
    }
  }

  ngOnChanges(changes: SimpleChanges): void {
    this.ngOnInit();
  }

  get inputSets() {
    return this.slotInputForm.get('inputSets') as FormArray;
  }

  addInputSet(index: number) {
    this.inputSets.push(this.generateInputSets(index));
  }

  generateInputSets(index: number) {
    return this.fb.group({
      setnr: [index],
      weight: [''],
      reps: [''],
    });
  }

  onSubmit() {
    this.saveSlot();
    this.save.emit(true);
  }

  textToNumber(text: string): number {
    let resnum: number;
    if (typeof text === 'string' && text.includes(',')) {
      resnum = Number(text.replace(',', '.'));
    } else {
      resnum = Number(text);
    }
    return resnum;
  }

  saveSlot() {
    if (!this.slot) throw Error('NO INPUT VARIABLE');

    this.slot.inputSets = [];
    this.slot.date = new Date();
    this.slot.comment = this.slotInputForm.value.comment as string;
    this.inputSets.controls.forEach((sets) => {
      let is = {};
      if (sets.get('weight')!.value !== '' && sets.get('reps')!.value !== '') {
        is = {
          setnr: sets.get('setnr')?.value,
          weight: this.textToNumber(sets.get('weight')?.value),
          reps: this.textToNumber(sets.get('reps')?.value),
        };
      } else if (sets.get('reps')!.value !== '') {
        is = {
          setnr: sets.get('setnr')?.value,
          reps: this.textToNumber(sets.get('reps')?.value),
        };
      } else if (sets.get('weight')!.value !== '') {
        is = {
          setnr: sets.get('setnr')?.value,
          weight: this.textToNumber(sets.get('weight')?.value),
        };
      }

      this.slot?.inputSets?.push(is);
    });
  }

  goBack() {
    this.saveSlot();
    this.back.emit(true);
  }

  cancelWorkout() {
    this.cancelCurrentWorkout.emit(true);
  }

  onsaveWorkoutlog() {
    this.saveSlot();

    this.saveWorkoutlog.emit(true);
  }

  checkForAMREPS(text: string | undefined) {
    if (text) {
      const conditions = ['amrap', 'amrep'];
      const result = conditions.some((el) => text.toLowerCase().includes(el));

      return result;
    } else {
      return false;
    }
  }
}
